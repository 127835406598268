<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>

  <el-dialog v-if="m" :title="'选择收款用户'" :visible.sync="isShow" width="480px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="分账用户选择:" placeholder="请选择">
            <el-select v-model="id" @change="initValue" @click.native="getAccount()" filterable v-el-select-loadmore="loadmore">
              <el-option v-for="item in accoutselectList" :key="item.id" :label="item.shareName" :value="item.shareOpenId + '|'+ item.providerMch + '|'+ item.wechatMch + '|'+item.mode" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      m: {
        orderId: "",
        mode: 0,
        wechatMch: "",
        providerMch: "",
        openId: "",
      },
      accoutselectList: [],
      p: {
        // 查询参数
        mchId: this.sa_admin.mchId,
        page: 1,
        limit: 100,
      },
    };
  },
  methods: {
    // 打开
    open: function (data, row) {
      this.id = data.id;
      this.m.orderId = row.receiverName;
      this.isShow = true;
    },
    getAccount: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
        msg: null,
      };
      this.sa.ajax(
        "/v1/profitshare_account/list",
        this.p,
        function (res) {
          this.accoutselectList = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    initValue: function (data) {
      let [shareOpenId, providerMch, wechatMch, mode] = data.split("|");
      this.m.mode = mode;
      this.m.openId = shareOpenId;
      this.m.wechatMch = wechatMch;
      this.m.providerMch = providerMch;
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    loadmore() {
      this.page++;
      this.getAccount(this.page);
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      let self = this;
      var defaultCfg = {
        type: "get", //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/profitshare_order/requestShareOrder",
        this.m,
        function () {
          self.$parent.f5(); // 父视图刷新
          self.isShow = false;
          self.claerData();
        },
        defaultCfg
      );
    },
    claerData() {
      this.m.orderId = "";
      this.m.openId = "";
      this.m.mode = "";
      this.m.wechatMch = "";
    },
  },
  created: function () { },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>

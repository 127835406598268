<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">微信分账订单管理</div>
      <el-form :inline="true" size="mini">
        <el-form-item>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
        </el-form-item>
        <br />
      </el-form>
      <el-table :data="dataList" size="mini" :header-cell-style="{ 'text-align': 'left' }" :cell-style="{ 'text-align': 'left' }">
        <el-table-column label="分账订单" prop="shareOrder" width="200px">
        </el-table-column>
        <el-table-column label="分账商户号" prop="shareMch" width="100px"></el-table-column>
        <el-table-column label="订单金额" prop="amount" width="120px">
          <template slot-scope="s">
            <el-tag effect="dark" size="mini">￥{{ s.row.fee / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="可分账金额" prop="amount" width="120px">
          <template slot-scope="s">
            <el-tag type="danger" size="mini" effect="dark">￥{{ s.row.shareFee / 100 }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="微信订单号" prop="receiverName" width="220px"></el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="150px"></el-table-column>
        <el-table-column label="状态" width="150px">
          <template slot-scope="s">
            <el-tag v-if="s.row.status == 1" size="mini" type="success" effect="dark">分账成功</el-tag>
            <el-tag v-if="s.row.status == 0" size="mini" type="warning" effect="dark">可以请求分账</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" width="220px">
          <template slot-scope="s">
            <el-button class="c-btn" type="primary" icon="el-icon-thumb" @click="select(s.row)">进行分账</el-button>
            <el-button class="c-btn" type="success" icon="el-icon-thumb" @click="profitsharingGet(s.row)">查询状态</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.pageSize" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
      <SwitchOpenId ref="switch-openid"></SwitchOpenId>
    </div>
  </div>
</template>

<script>
import SwitchOpenId from "../order/switch-openid.vue";
export default {
  components: { SwitchOpenId },
  data() {
    return {
      p: {
        page: 1,
        limit: 10,
        shardOrderId: null
      },
      dataCount: 0,
      dataList: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.sa.ajax(
        "/v1/profitshare_order/list",
        this.p,
        function (res) {
          this.dataList = res.data.records; // 数据
          this.dataCount = res.data.total; // 分页
        }.bind(this),
        defaultCfg
      );
    },
    select: function (data) {
      this.$refs["switch-openid"].open(0, data);
    },
    profitsharingGet: function (data) {
      var defaultCfg = {
        type: "get", // 默认请求类型
      };
      this.p.shardOrderId = data.id;
      this.sa.ajax(
        "/v1/profitshare_order/profitsharingGet",
        this.p,
        function (res) {
          var t = JSON.parse(res.data);
          var rece = t.receivers[1];
          var rece2 = t.receivers[0];
          if (rece != null && rece != 'undefined' && rece.type === "PERSONAL_OPENID") {
            if (rece.result === "SUCCESS") {
              this.sa.ok("分账成功");
            } else if (rece2.result === "PENDING") {
              this.sa.ok("分账处理中,请耐心等待");
            } else {
              if (rece.fail_reason === "ACCOUNT_ABNORMAL") {
                this.sa.error("分账失败,原因:分账接收账户异常");
              } else if (rece.fail_reason === "NO_RELATION") {
                this.sa.error("分账失败,原因:分账关系已解除，请重新添加");
              } else if (rece.fail_reason === "RECEIVER_REAL_NAME_NOT_VERIFIED") {
                this.sa.error("分账失败,原因:分账接收方未实名");
              } else if (rece.fail_reason === "NO_AUTH") {
                this.sa.error("分账失败,原因:分账权限已解除");
              } else if (rece.fail_reason === "RECEIVER_RECEIPT_LIMIT") {
                this.sa.error("分账失败,原因:超出用户月收款限额");
              } else if (rece.fail_reason === "PAYER_ACCOUNT_ABNORMAL") {
                this.sa.error("分账失败,原因:分出方账户异常");
              }

            }
          }
          if (rece2 != null && rece2 != 'undefined' && rece2.type === "PERSONAL_OPENID") {
            if (rece2.result === "SUCCESS") {
              this.sa.ok("分账成功");
            } else if (rece2.result === "PENDING") {
              this.sa.ok("分账处理中,请耐心等待");
            }
            else {
              if (rece2.fail_reason === "ACCOUNT_ABNORMAL") {
                this.sa.error("分账失败,原因:分账接收账户异常");
              } else if (rece2.fail_reason === "NO_RELATION") {
                this.sa.error("分账失败,原因:分账关系已解除，请重新添加");
              } else if (rece2.fail_reason === "RECEIVER_REAL_NAME_NOT_VERIFIED") {
                this.sa.error("分账失败,原因:分账接收方未实名");
              } else if (rece2.fail_reason === "NO_AUTH") {
                this.sa.error("分账失败,原因:分账权限已解除");
              } else if (rece2.fail_reason === "RECEIVER_RECEIPT_LIMIT") {
                this.sa.error("分账失败,原因:超出用户月收款限额");
              } else if (rece2.fail_reason === "PAYER_ACCOUNT_ABNORMAL") {
                this.sa.error("分账失败,原因:分出方账户异常");
              }
            }
          }

        }.bind(this),
        defaultCfg
      );
    }
  },
  created: function () {
    this.f5();
  },
};
</script>

<style>
</style>
